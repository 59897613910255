import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'

import { ChevronLeftIcon, ChevronRightIcon, Wrapper, Image } from '@farewill/ui'
import { useWindowSize } from 'react-use'
import { BORDER_RADIUS, BREAKPOINT, GTR } from '@farewill/ui/tokens'
import { screenMax, screenMin } from '@farewill/ui/helpers/responsive'
import { CarouselProvider, Slide, Slider } from 'pure-react-carousel'

import BreakpointSwitch from 'components/BreakpointSwitch'

import CarouselSlide from './CarouselSlide'
import { SLIDES_TOP } from './constants'
import {
  StyledBackButton,
  StyledNextButton,
} from '../components/CarouselButtons'
import { Dots } from '../components/CarouselDots'

const BUTTON_SIZE = 32

const StyledWrapper = styled(Wrapper)`
  overflow: hidden;
  position: relative;
`

const StyledCarouselWrapper = styled(Wrapper)`
  position: relative;
  max-width: calc(100% - ${BUTTON_SIZE * 2}px);
  margin: 0 auto;

  img {
    transition: all 0.4s ease-in-out;
    transition-delay: 0.2s;
    opacity: 1;
  }

  .carousel__slide--hidden {
    img {
      transform: scale(0.7);
      opacity: 0;
    }
  }

  ${screenMax.m`
    margin-left: -${GTR.L};
    margin-right: -${GTR.L};
    max-width: none;

    .carousel__slider {
      padding-left: ${GTR.M};
      padding-right: ${GTR.M};
    }

    .carousel__slide {
      transition: all 0.4s ease-in-out;
    }

    .carousel__slide--visible + .carousel__slide--visible {
      img {
        transform: scale(0.7);
        opacity: 0;
      }
    }
  `}

  ${screenMax.s`
  .carousel__slide--visible + .carousel__slide--visible {
    opacity: 0.5;
  }
  `}

  ${screenMin.m`
    .carousel__dot--selected:disabled {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .carousel__dot--selected + .carousel__dot--selected {
      border-radius: ${BORDER_RADIUS.M};
      border-top-left-radius:0;
      border-bottom-left-radius:0;
    }
  `}
`

const TimelineCarousel = (): ReactElement => {
  const windowSize = useWindowSize()
  const [visibleSlides, setVisibleSlides] = useState(4)
  const [isMobile, setIsMobile] = useState(false)
  const [isTablet, setIsTablet] = useState(false)
  useEffect(() => {
    if (isMobile && visibleSlides !== 1) {
      setVisibleSlides(1.2)
    } else if (isTablet && visibleSlides !== 2) {
      setVisibleSlides(2)
    } else if (!isMobile && !isTablet && visibleSlides !== 4) {
      setVisibleSlides(4)
    }
  }, [windowSize, isMobile, isTablet])

  useEffect(() => {
    setIsMobile((windowSize.width || 0) < BREAKPOINT.S)

    setIsTablet(
      (windowSize.width || 0) > BREAKPOINT.S &&
        (windowSize.width || 0) < BREAKPOINT.M
    )
  }, [windowSize])

  return (
    <StyledWrapper container containerPaddingTop={0} containerPaddingBottom={0}>
      <BreakpointSwitch
        breakAt={BREAKPOINT.M}
        belowComponent={
          <Wrapper centered margin={['M', 0]} marginFromM={['M', 0, 'XL']}>
            <Image path="e_trim/illustrations/welcome" width={300} />
          </Wrapper>
        }
      />
      <StyledCarouselWrapper padding={['M', 0, 0]} paddingFromM={0}>
        <CarouselProvider
          totalSlides={SLIDES_TOP.length}
          visibleSlides={visibleSlides}
          naturalSlideWidth={280}
          naturalSlideHeight={200}
          isIntrinsicHeight
          lockOnWindowScroll
        >
          <Slider preventVerticalScrollOnTouch>
            {SLIDES_TOP.map((slide, i) => (
              <Slide
                style={{
                  width: '95%',
                }}
                index={i}
                key={slide.id}
              >
                <CarouselSlide index={i} />
              </Slide>
            ))}
          </Slider>

          <Dots isMobile={isMobile} />
          <StyledBackButton>
            <ChevronLeftIcon size="M" />
          </StyledBackButton>
          <StyledNextButton>
            <ChevronRightIcon size="M" />
          </StyledNextButton>
        </CarouselProvider>
      </StyledCarouselWrapper>
    </StyledWrapper>
  )
}

export default TimelineCarousel
