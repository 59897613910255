import React, { ReactElement, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { CarouselContext, DotGroup } from 'pure-react-carousel'
import { BORDER_RADIUS, COLOR, GTR } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'

import { VALUES } from 'components/ValuesCard/data'

const BUTTON_SIZE = 32

const StyledDotGroup = styled(DotGroup)`
  display: flex;
  grid-template-columns: repeat(${VALUES.length}, 1fr);
  background: rgba(0, 0, 0, 0.05);
  margin-top: ${GTR.S};
  margin-left: auto;
  margin-right: auto;
  max-width: calc(100% - ${BUTTON_SIZE * 2}px);
  border-radius: ${BORDER_RADIUS.M};

  button {
    width: 100%;
    height: 6px;

    &:disabled {
      border-radius: ${BORDER_RADIUS.M};
      background: ${COLOR.GREY.MEDIUM};
    }
  }

  ${screenMin.l`
    display: none;
  `}
`

export const Dots = ({ isMobile }: { isMobile: boolean }): ReactElement => {
  const carouselContext = useContext(CarouselContext)
  const [currentSlide, setCurrentSlide] = useState(
    carouselContext.state.currentSlide
  )

  /**
   * Slightly ugly workaround for the carousel. As we want to display a fraction of next slide
   * the final slide is counted as nr 4.8 not nr 5, and after that, going backwards, the library
   * is doing some dodgy calculation for the slides, so each of the next ones is also not an int.
   * This calculates the proper value for each slide and sets it in the store.
   */
  useEffect(() => {
    const lastElementIndex = VALUES.length - 1
    function onChange() {
      const currentStateSlide = carouselContext.state.currentSlide
      const isInt = Number.isInteger(currentStateSlide)
      const isLastItem = Math.ceil(currentStateSlide) === lastElementIndex

      if (!isInt && !isLastItem) {
        carouselContext.setStoreState({
          currentSlide: Math.ceil(currentStateSlide),
        })
        setCurrentSlide(Math.ceil(carouselContext.state.currentSlide))
      }

      setCurrentSlide(carouselContext.state.currentSlide)
    }
    carouselContext.subscribe(onChange)
    return () => carouselContext.unsubscribe(onChange)
  }, [carouselContext])

  /**
   * Slightly ugly workaround for the carousel. As we want to display a fraction of next slide
   * the final slide is counted as nr 4.8 not nr 5, therefore the "dots" are not displayed correctly.
   * This is calculating the current slide and setting active dot to correct one.
   */
  useEffect(() => {
    const dotElement = document.querySelectorAll(`.carousel__dot`)
    const lastElement = dotElement[dotElement.length - 1] as HTMLButtonElement

    if (!Number.isInteger(currentSlide)) {
      lastElement.classList.add('carousel__dot--selected')
      lastElement.disabled = true
    } else {
      lastElement.classList.remove('carousel__dot--selected')
      lastElement.disabled = false
    }
  }, [currentSlide])
  return <StyledDotGroup showAsSelectedForCurrentSlideOnly={isMobile} />
}
