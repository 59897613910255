import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { H, P, Wrapper, Image } from '@farewill/ui'
import { BORDER_RADIUS, COLOR } from '@farewill/ui/tokens'

const StyledCard = styled(Wrapper)`
  border-radius: ${BORDER_RADIUS.XL};
  height: 100%;
`

export type ValuesCardProps = {
  title: string
  description: string
  illustration: string
}

const ValuesCard = ({
  title,
  description,
  illustration,
}: ValuesCardProps): ReactElement => (
  <StyledCard background={COLOR.ACCENT.PRIMARY_60} padding={[0, 'M', 'L']}>
    <Wrapper centered>
      <Image path={illustration} width={600} stretch loading="auto" />
    </Wrapper>
    <H tag="h4" margin={[0, 0, 'XS']}>
      {title}
    </H>
    <P color={COLOR.BLACK}>{description}</P>
  </StyledCard>
)

export default ValuesCard
