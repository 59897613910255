export const BENEFITS = [
  {
    name: 'Excellent compensation',
    description: 'A competitive salary benchmarked across other startups',
  },
  {
    name: 'Hybrid working',
    description:
      'The option to work from home, with two days a week in the office with your team',
  },
  {
    name: 'A great working environment',
    description: 'A bright, modern office with coffee, fruit and snacks on tap',
  },
  {
    name: 'Friends and family discounts',
    description:
      '50% off wills and 20% off probate and direct cremations, for friends and family',
  },
  {
    name: 'Time to recharge',
    description:
      '25 days holiday + bank holidays and flexible working, dependent on your role',
  },
  {
    name: 'Team socials',
    description:
      'Regular offsites and team lunches to get to know your collegues',
  },
  {
    name: 'Well-being line',
    description: 'Mental health support from trained counsellors',
  },
]
